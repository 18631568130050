const i18n = {
  'en-US': {
    'system.title': 'WACSMART',
    'welcome': 'Welcome Back!',
    'menu.home': 'Dashboard',
    'menu.approvalCenter': 'Approval Center',
    'menu.dataSearch': 'Data Search',
    'menu.tutorials': 'Tutorials',
    'menu.userCenter': 'Personal Center',
    'menu.messages': 'Message Center',
    'menu.staffManager': 'Staff Management',
    'menu.user.info': 'User Info',
    'menu.user.setting': 'User Setting',
    'menu.user.switchRoles': 'Switch Roles',
    'menu.user.role.admin': 'Admin',
    'menu.user.role.user': 'General User',
    'menu.user.role.center': 'Personal Center',
    'menu.user.role.staffs': 'Staff Management',
    'navbar.logout': 'Logout',
    'settings.title': 'Settings',
    'settings.themeColor': 'Theme Color',
    'settings.content': 'Content Setting',
    'settings.navbar': 'Navbar',
    'settings.menuWidth': 'Menu Width (px)',
    'settings.navbar.theme.toLight': 'Click to use light mode',
    'settings.navbar.theme.toDark': 'Click to use dark mode',
    'settings.menu': 'Menu',
    'settings.footer': 'Footer',
    'settings.otherSettings': 'Other Settings',
    'settings.colorWeek': 'Color Week',
    'settings.alertContent': 'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    'settings.copySettings': 'Copy Settings',
    'settings.copySettings.message': 'Copy succeeded, please paste to file src/settings.json.',
    'settings.close': 'Close',
    'settings.color.tooltip': '10 gradient colors generated according to the theme color',
    'message.tab.title.message': 'Message',
    'message.tab.title.notice': 'Notice',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'All Read',
    'message.seeMore': 'SeeMore',
    'message.empty': 'Empty',
    'message.empty.tips': 'No Content',
    'message.lang.tips': 'Language switch to ',
    'navbar.search.placeholder': 'Please search',
    'navbar.app.download': 'App Download'
  },
  'zh-CN': {
    'system.title': '唯希',
    'welcome': "欢迎回来!",
    'menu.home': '工作台',
    'menu.approvalCenter': '审批中心',
    'menu.approvalCenter.create': '发起审批',
    'menu.dataSearch': '数据查询',
    'menu.tutorials': '操作教程',
    'menu.userCenter': '个人中心',
    'menu.messages': '消息中心',
    'menu.staffsManager': '员工管理',
    'menu.user.info': '用户信息',
    'menu.user.setting': '用户设置',
    'menu.user.switchRoles': '切换角色',
    'menu.user.role.admin': '管理员',
    'menu.user.role.user': '普通用户',
    'menu.user.role.center': '个人中心',
    'menu.user.role.staffs': '员工管理',
    'navbar.logout': '退出登录',
    'settings.title': '页面配置',
    'settings.themeColor': '主题色',
    'settings.content': '内容区域',
    'settings.navbar': '导航栏',
    'settings.menuWidth': '菜单宽度 (px)',
    'settings.navbar.theme.toLight': '点击切换为亮色模式',
    'settings.navbar.theme.toDark': '点击切换为暗黑模式',
    'settings.menu': '菜单栏',
    'settings.footer': '底部',
    'settings.otherSettings': '其他设置',
    'settings.colorWeek': '色弱模式',
    'settings.alertContent': '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
    'settings.copySettings': '复制配置',
    'settings.copySettings.message': '复制成功，请粘贴到 src/settings.json 文件中',
    'settings.close': '关闭',
    'settings.color.tooltip': '根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）',
    'message.tab.title.message': '消息',
    'message.tab.title.notice': '通知',
    'message.tab.title.todo': '待办',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无内容',
    'message.lang.tips': '语言切换至 ',
    'navbar.search.placeholder': '输入内容查询',
    'navbar.app.download': 'app 下载',
    'select.normal.placeholder': '请选择',
    'approval.type.all': '全部类型',
    'approval.type.type1': '费用报销',
    'approval.type.type1.desc': '差旅费、团建费等各类报销',
    'approval.type.type2': '物资领用',
    'approval.type.type2.desc': '申请领用物品等流程',
    'approval.type.type3': '付款申请',
    'approval.type.type3.desc': '现金、支票等各类付款申请',
    'approval.type.type4': '收款单',
    'approval.type.type4.desc': '收款单申请',
    'approval.type.type5': '借款单',
    'approval.type.type5.desc': '借款单申请',
    'approval.type.type6': '其他收入单',
    'approval.type.type7': '未知类型',
    'approval.tab.tab1': '待办',
    'approval.tab.tab2': '已办',
    'approval.tab.tab3': '已发起',
    'approval.tab.tab4': '抄送我',
    'approval.filter.type1': '最新达到',
    'approval.filter.type2': '最晚到达',
    'approval.filter.type3': '最早处理',
    'approval.filter.type4': '最晚处理',
    'approval.status.all': '全部状态',
    'approval.status.status1': '审批中',
    'approval.status.status2': '已完成',
    'approval.status.status3': '已拒绝',
    'approval.status.status4': '未提交',
    'approval.status.status5': '已做账',
    'approval.status.status6': '已提交',
    'approval.status.status7': '已通过',
    'approval.status.status9': '已撤回',
    'approval.status.status10': '已反审',
    'approval.status.status11': '已抄送',
    'approval.status.status12': '已修改',
    'approval.status.status13': '未审批',
    'approval.status.status14': '已从ERP撤回',
    'approval.status.status15': '已结束',
    'approval.time.txt': '提交于',
    'approval.field.number': '编号',
    'approval.field.remark': '备注',
    'approval.field.noRemark': '暂无备注',
    'approval.field.detail': '明细汇总',
    'approval.field.unit1': '数量',
    'approval.field.unit2': '金额',
    'approval.field.unit3': '税额',
    'approval.field.invoiceNum': '发票号码',
    'approval.field.summaryField1': '明细汇总-含税金额',
    'approval.field.summaryField2': '明细汇总-金额',
    'approval.field.summaryField3': '明细汇总-数量',
    'approval.field.pdfName1': '费用报销单',
    'approval.field.pdfName2': '物资领用单',
    'approval.field.pdfName3': '付款申请单',
    'approval.field.pdfName4': '收款单',
    'approval.field.pdfName5': '借款单',
    'approval.field.pdfName6': '其他收入单',
    'approval.field.esan': '结算账户',
    'approval.field.realName1': '报销人',
    'approval.field.realName2': '领用人',
    'approval.field.realName3': '付款申请人',
    'approval.field.realName4': '收款申请人',
    'approval.field.realName5': '借款人',
    'approval.field.date': '日期 (年-月-日)',
    'approval.field.typeName': '类别',
    'approval.field.sTypeName': '收发类别',
    'approval.field.eaan': '辅助核算',
    'approval.field.taxAmount': '税额 (人民币)',
    'approval.field.eait': '含税金额 (人民币)',
    'approval.field.amount': '金额 (人民币)',
    'approval.field.commodity': '商品',
    'approval.field.despository': '调出仓库',
    'approval.field.supplier': '供应商',
    'approval.field.customer': '客户',
    'approval.field.outAccount': '转出账户',
    'approval.field.inAccount': '转入账户',
    'approval.field.placeholder1': '请选择',
    'approval.field.placeholder2': '增值税专用发票',
    'approval.field.placeholder3': '请输入',
    'approval.field.placeholder4': '请输入, 不超过50字',
    'approval.field.placeholder5': '发票号码, 可输入多个, 一行一个',
    'approval.file.name': '附件',
    'approval.file.upload': '上传',
    'approval.file.upload.tip': '可上传图片, PDF, 每个最大不超过16M',
    'approval.file.upload.tip2': '文件大小不能超过16M',
    'approval.file.upload.tip3': '暂不支持pdf预览',
    'approval.file.upload.tip4': '文件预览出错, 文件内容不存在',
    'approval.file.upload.tip5': '审批流程已经发起，不能再上传附件',
    'approval.file.upload.tip6': '审批流程中的附件不可删除',
    'approval.file.upload.tip7': '审批中或已完成状态不可修改审批流程',
    'approval.pdf.title': '实时PDF单据',
    'approval.pdf.submit': '提交人',
    'approval.pdf.date': '日期',
    'approval.pdf.file': '附件数',
    'approval.pdf.total': '合计',
    'approval.footer.master': '审核人',
    'approval.footer.finance': '财务审核人',
    'approval.footer.cashier': '出纳放款人',
    'approval.footer.warehouse': '仓库管理员',
    'approval.footer.cashier2': '收款确认人',
    'approval.flow.title': '审批流程',
    'approval.flow.add': '添加',
    'approval.flow.select': '选择人员',
    'approval.flow.submit': '发起',
    'approval.flow.submit2': '提交',
    'approval.flow.notSubmit': '未提交',
    'approval.flow.check': '审核',
    'approval.flow.check2': '审批',
    'approval.flow.notCheck': '未审批',
    'approval.flow.finance': '财务审核',
    'approval.flow.cashier': '出纳放款',
    'approval.flow.cashier2': '收款确认',
    'approval.flow.copy': '抄送',
    'approval.flow.end': '结束',
    'approval.flow.statusTxt': '流程状态',
    'approval.detail.title1': '审批详情',
    'approval.detail.title2': '审批记录',
    'approval.detail.title3': '费用明细',
    'approval.detail.title4': '物资明细',
    'approval.action.pass': '通过',
    'approval.action.reject': '拒绝',
    'approval.action.modify': '修改',
    'approval.action.reverse': '反审',
    'approval.action.cc': '抄送',
    'approval.action.withdrawFromErp': '从ERP撤回',
    'approval.action.download': '下载为PDF',
    'approval.action.delete': '删除',
    'approval.action.urge': '催办',
    'approval.action.withdraw': '撤销',
    'approval.action.save': '保存草稿',
    'approval.action.submit': '提交',
    'approval.create.add': '添加明细行',
    'approval.select.tip1': '请先填写',
    'approval.select.tip2': '审批流程已经开始，不允许修改',
    'approval.submit.empty': '不能为空',
    'approval.submit.success': '提交成功',
    'approval.save.success': '保存成功',
    'loadMore.loading': '加载中...',
    'loadMore.startLoad': '下滑加载更多',
    'loadMore.failed': '加载失败, 下滑重新加载',
    'noMore.tips': '没有更多数据了~',
    'noMore.button': '点击加载更多',
    'api.message.error': '未知异常, 请稍后重试',
    'file.view.error1': '文件不存在, 预览失败',
    'file.view.error2': '暂不支持该类型文件查看',
    'modal.confirm.title': '提示',
    'modal.operate.success': '操作成功',
    'data.query.title': '数据查询',
    'data.query.latestPurchasePrice': '最新采购价格查询',
    'data.query.latestBomCost': '最新BOM成本查询',
    'data.query.latestSalesPrice': '最新销售价格查询',
    'data.query.inventoryQuery': '库存查询',
    'data.query.inventoryQuery30': '30天久滞库存查询',
    'data.query.inventoryQuery60': '60天久滞库存查询',
    'data.query.inventoryQuery90': '90天久滞库存查询',
    'data.query.receivableQuery': '应收查询',
    'data.query.payableQuery': '应付查询',
    'data.query.expenseQueryDept': '费用查询(部门)',
    'data.query.expenseQueryStaff': '费用查询(员工)',
    'data.query.customerProfitAnalysis': '客户获利分析',
    'data.query.salesReportCust': '销售报表(客户)',
    'data.query.salesReportStaff': '销售报表(业务员)',
    'data.query.filter1': '全部商品',
    'data.query.filter2': '全部客户',
    'data.query.filter3': '全部供应商',
    'data.query.filter4': '全部部门',
    'data.query.filter5': '全部员工',
    'data.query.filter6': '全部业务员',
    'data.query.export': '导出',
    'data.query.export.tip': '功能正在开发中...',
    'data.query.total.title': '合计',
    'videos.title': '操作视频',
    'videos.time': '时长'
  }
};
export default i18n;