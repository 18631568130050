/**
 * 用户相关
 */
import apiService from "@/utils/request";

/**
 * 云账号登录
 */
export const accountLogin = params => {
  return apiService.post('/staff/login', params);
};
/**
 * 云账号登出
 */

export const accountLogout = () => {
  return apiService.post('/staff/logout');
};
/**
 * 修改手机号/更新密码/更新邮箱-发送旧手机号验证码
 */

export const changeMesSendOldSms = params => {
  let url = '/user/phone/old/send?verify_type=' + params.verify_type;

  if (params.phone) {
    url += '&phone=' + params.phone;
  }

  if (params.cloud_account) {
    url += '&cloud_account=' + params.cloud_account;
  }

  return apiService.put(url);
};
/**
 * 修改手机号/更新密码/更新邮箱-验证旧手机
 */

export const changeMesVerifyOld = params => {
  let url = '/user/phone/old/verify?verify_code=' + params.verify_code;

  if (params.phone) {
    url += '&phone=' + params.phone;
  }

  if (params.cloud_account) {
    url += '&cloud_account=' + params.cloud_account;
  }

  return apiService.put(url);
};
/**
 * 修改手机号-发送新手机号验证码
 */

export const changePhoneSendNewSms = params => {
  return apiService.put('/user/phone/new/send?phone=' + params.phone);
};
/**
 * 修改手机号-验证新手机
 */

export const changePhoneVerifyNew = params => {
  return apiService.put('/user/phone/new/verify?phone=' + params.phone + '&verify_code=' + params.verify_code);
};
/**
 * 修改密码-验证新密码
 */

export const changePwdVerify = params => {
  return apiService.put('/user/password', params);
};
/**
 * 修改邮箱-验证新邮箱
 */

export const changeEmailVerify = params => {
  return apiService.put('/user/email?email=' + params.email);
};