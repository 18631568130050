import axios from 'axios';
import qs from 'query-string'; // 定义类型谓词，用于确定对象是否具有 code 属性

function hasCodeProperty(obj) {
  return typeof obj === 'object' && 'code' in obj;
} // 定义API基类，包含通用配置


class BaseApi {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
      timeout: 60000
    });
  } // 添加请求拦截器


  setupInterceptors() {
    this.api.interceptors.request.use(async config => {
      const userInfoStr = localStorage.getItem('userInfo');
      const userInfo = userInfoStr ? JSON.parse(userInfoStr) : {};
      const authToken = userInfo.token || '';

      if (authToken) {
        config.headers = { ...config.headers,
          'Content-Type': 'application/json',
          'x-auth-token': authToken
        };
      } else {
        config.headers = { ...config.headers,
          'Content-Type': 'application/json'
        };
      }

      return config;
    }, error => Promise.reject(error));
    this.api.interceptors.response.use(response => {
      if (response.status === 200) {
        const data = response.data;

        if (hasCodeProperty(data)) {
          if (data.code === 442 || data.code === 438 || data.code === 406) {
            localStorage.removeItem('userInfo');
            window.location.href = '/login';

            if (data.code === 406) {
              return Promise.reject({
                code: -1,
                message: '您已被禁用, 如有疑问, 请联系管理员处理'
              });
            } else {
              return Promise.reject({
                code: -1,
                message: '登录过期，请重新登录'
              });
            }
          }

          return data;
        }

        return response.data;
      }

      return Promise.reject(response);
    }, error => {
      const errMsg = error.message || '网络错误，请稍后重试';

      if (error.code === 'ECONNABORTED') {
        return Promise.reject({
          code: -1,
          message: '请求超时，请稍后重试'
        });
      }

      return Promise.reject({
        code: -1,
        message: errMsg
      });
    });
  }

} // 实现具体的API类，包含GET、POST、PUT、DELETE方法


export class ApiService extends BaseApi {
  constructor(baseURL) {
    super(baseURL);
    this.setupInterceptors();
  }

  async get(url, params, config) {
    try {
      const newNrl = params ? qs.stringifyUrl({
        url: url,
        query: params
      }) : url;
      const response = await this.api.get(newNrl, config);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async post(url, data, config) {
    try {
      const response = await this.api.post(url, data, config);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async put(url, data, config) {
    try {
      const response = await this.api.put(url, data, config);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(url, params, config) {
    try {
      const newNrl = params ? qs.stringifyUrl({
        url: url,
        query: params
      }) : url;
      const response = await this.api.delete(newNrl, config);
      return response;
    } catch (error) {
      throw error;
    }
  }

} // 导出实例化后的API服务

const apiService = new ApiService(process.env.REACT_APP_BASE_URL);
export default apiService;