/**
 * 信息展示模块
 */
import Api from "@/utils/request";
export const getCarouse = () => {
  return Api.get('/public-info/banner');
};
/**
 * 获取联系我们信息
 */

export const getContactUs = () => {
  return Api.get('/public-info/wac-contact');
};
/**
 * 全局搜索
 */

export const search = params => {
  return Api.get('/global-search', params);
};
/**
 * 获取热门搜索
 */

export const getHotSearch = () => {
  return Api.get('/global-search/hot');
};
/**
 * 记录搜索词(点击功能的时候调用)
 */

export const recordSearchWord = word => {
  return Api.post('/global-search/hot?value=' + word);
};
/** 
 * 获取操作视频
*/

export const getOperationVideo = () => {
  return Api.get('/public-info/operation-video');
};
/**
 * 获取公司信息
 */

export const getCompanyInfo = () => {
  return Api.get('/company/name');
};
/**
 * 获取公告
 */

export const getNoticeList = params => {
  return Api.get('/public-info/company-announcement', params);
};
/**
 * 获取公告详情
 */

export const getNoticeDetail = announcement_id => {
  return Api.get(`/public-info/company-announcement/${announcement_id}`);
};
/**
 * 上传报错日志
 */

export const uploadErrorLog = params => {
  return Api.post('/front_end_log', params);
};