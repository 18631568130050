const rolePermissions = {
  'menu.home': ['*'],
  'menu.userCenter': ['*'],
  'menu.staffsManage': ['*'],
  'menu.messages': ['*'],
  'menu.approvalCenter': ['*'],
  'menu.approvalCenter.create': ['*'],
  'menu.dataSearch': ['*'],
  'menu.dataSearch.detail': ['*'],
  'menu.tutorials': ['*'],
  'admin-operate': ['*']
};
export default rolePermissions;