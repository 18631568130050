const i18n = {
  'en-US': {
    'login.form.title': 'Login WACSMART',
    'login.form.cloud.title': 'Cloud Account',
    'login.form.cloud.errMsg': 'Cloud account cannot be empty',
    'login.form.userName.title': 'Phone Number',
    'login.form.userName.errMsg': 'Phone number cannot be empty',
    'login.form.password.title': 'Password',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.verifyCode.title': 'Verification code',
    'login.form.verifyCode.errMsg': 'Verification code cannot be empty',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.cloud.placeholder': 'Please enter the cloud account',
    'login.form.userName.placeholder': 'Please enter the phone number',
    'login.form.password.placeholder': 'Please enter the password',
    'login.form.verifyCode.placeholder': 'Please enter the verification code',
    'login.form.verifyCode.send': 'Send',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password?',
    'login.form.login': 'login'
  },
  'zh-CN': {
    'login.form.title': '登录 唯希',
    'login.form.cloud.title': '云账号',
    'login.form.cloud.errMsg': '云账号不能为空',
    'login.form.userName.title': '手机号',
    'login.form.userName.errMsg': '手机号不能为空',
    'login.form.password.title': '密码',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.verifyCode.title': '验证码',
    'login.form.verifyCode.errMsg': '验证码不能为空',
    'login.form.login.errMsg': '登录出错，请刷新重试',
    'login.form.cloud.placeholder': '请输入云账号',
    'login.form.userName.placeholder': '请输入手机号',
    'login.form.password.placeholder': '请输入密码',
    'login.form.verifyCode.placeholder': '请输入验证码',
    'login.form.verifyCode.send': '发送',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码?',
    'login.form.login': '登录'
  }
};
export default i18n;