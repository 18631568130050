import defaultSettings from '../settings.json';
const initialState = {
  settings: defaultSettings,
  userInfo: {
    userId: '',
    userToken: '',
    isEnterPrise: false,
    realName: '',
    cloudAccount: '',
    isAdmin: false,
    permissions: {}
  },
  messsageUnReadNum: 0,
  userLoading: false,
  wsMessageInfo: {
    message_id: '',
    title: '',
    remark: '',
    create_time: ''
  },
  companyInfo: {
    companyName: ''
  }
};
export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings':
      {
        const {
          settings
        } = action.payload;
        return { ...state,
          settings
        };
      }

    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo,
          userLoading
        } = action.payload;
        return { ...state,
          userLoading,
          userInfo
        };
      }

    case 'update-messsageUnReadNum':
      {
        const {
          messsageUnReadNum
        } = action.payload;
        return { ...state,
          messsageUnReadNum
        };
      }

    case 'update-messsage':
      {
        const {
          wsMessageInfo
        } = action.payload;
        return { ...state,
          wsMessageInfo
        };
      }

    case 'update-companyInfo':
      {
        const {
          companyInfo
        } = action.payload;
        return { ...state,
          companyInfo
        };
      }

    default:
      return state;
  }
}