import apiService from "@/utils/request";

/**
 * 消息列表
 */
export const getMessageList = params => {
  return apiService.get('/message', params);
};
/**
 * 获取未读数量
 */

export const getUnReadMessageCount = () => {
  return apiService.get('/message/unread/num');
};
/**
 * 清空未读
 */

export const clearUnReadMessage = params => {
  return apiService.delete('/message/read', params);
};