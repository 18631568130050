import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: 'menu.home',
  key: 'home',
  requiredPermissions: [{
    resource: "menu.home",
    actions: ['read']
  }],
  breadcrumb: false,
  oneOfPerm: true
}, //工作台
{
  name: 'menu.approvalCenter',
  key: 'approvalCenter/detail/index',
  requiredPermissions: [{
    resource: "menu.approvalCenter",
    actions: ['read']
  }],
  breadcrumb: false
}, //审批中心
{
  name: 'menu.approvalCenter.create',
  key: 'approvalCenter/create/index',
  requiredPermissions: [{
    resource: "menu.approvalCenter.create",
    actions: ['read']
  }],
  ignore: true,
  breadcrumb: false
}, //发起审批
{
  name: 'menu.dataSearch',
  key: 'dataSearch/query/index',
  requiredPermissions: [{
    resource: "menu.dataSearch",
    actions: ['read']
  }],
  breadcrumb: false
}, //数据查询
{
  name: 'menu.dataSearch.detail',
  key: 'dataSearch/detail/index',
  requiredPermissions: [{
    resource: "menu.dataSearch.detail",
    actions: ['read']
  }],
  breadcrumb: false,
  ignore: true
}, //数据查询-详情
{
  name: 'menu.tutorials',
  key: 'tutorials',
  requiredPermissions: [{
    resource: "menu.tutorials",
    actions: ['read']
  }],
  breadcrumb: false
}, //操作教程
{
  name: 'menu.userCenter',
  key: 'userCenter',
  requiredPermissions: [{
    resource: "menu.userCenter",
    actions: ['read']
  }],
  ignore: true
}, //用户中心
{
  name: 'menu.staffsManage',
  key: 'staffsManage',
  requiredPermissions: [{
    resource: "menu.staffsManage",
    actions: ['read']
  }],
  ignore: true,
  breadcrumb: false
} //员工管理
// {
//     name: 'menu.messages',
//     key: 'messages',
//     requiredPermissions: [
//         { resource: "menu.messages", actions: ['read']}
//     ],
//     ignore: true,
// }, //消息中心
];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }

    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;

      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) {
        continue;
      }

      if (route.children && route.children.length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;