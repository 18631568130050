export const pageSize = 10;
/**
 * 判断密码是否符合要求
 */

export function passwordIsValid(password) {
  // 正则表达式：密码必须包含字母和数字，长度在8-20之间
  const regex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z\d!@#$%^&*()-_+=<>?/.,;:~`]{8,20}$/;
  return regex.test(password);
}
/**
 * 判断手机号是否符合要求
 */

export function phoneIsValid(str) {
  //11位数字
  const pattern = /^[0-9]{11}$/;
  return pattern.test(str);
}
/**
 * 判断邮箱是否符合要求
 */

export const checkEmail = email => {
  // 正则表达式：邮箱格式校验
  const regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  return regex.test(email);
};
/**
 * 格式化金额，优化处理不规范输入
 * @param {string} rawAmount - 原始金额字符串，可能包含不规范的格式
 * @param {boolean} needFormat - 是否需要格式化，默认不需要
 * @param {number} [decimals=2] - 期望保留的小数位数，默认为2
 * @returns {string} 返回格式化后的合法金额字符串，或空字符串表示输入完全不合法
 */

export function formatAmountOptimized(rawAmount, needFormat = false, decimals = 2) {
  // 清理输入，只保留数字和第一个小数点
  let cleanedAmount = rawAmount.replace(/[^\d.]/g, ''); // 移除非数字和非小数点字符

  cleanedAmount = cleanedAmount.replace(/\.(?=.*\.)/g, ''); // 移除多余的小数点
  // 检查清理后的字符串是否合法（至少有一个数字）

  if (!/\d/.test(cleanedAmount)) {
    return ''; // 如果没有数字，则返回空字符串
  } // 将清理后的字符串转换为数值


  const number = Number(cleanedAmount);

  if (isNaN(number)) {
    return ''; // 转换失败也返回空字符串
  } // 使用toFixed限制小数位数并转换为字符串


  let formatted = number.toFixed(decimals); // 如果小数部分都是零，则去掉小数点和后面的零

  formatted = formatted.replace(/\.0+$/, '');

  if (needFormat) {
    // 使用正则表达式添加千位分隔符
    formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return formatted;
}
/**
 * 下载base64格式的文件
 * @param base64Data 源文件
 * @param fileName 文件名
 * @param extName 文件扩展名
 */

export const downloadBase64File = (base64Data, fileName, extName) => {
  // 将Base64编码的数据转换为Blob对象
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {
    type: 'application/octet-stream'
  }); // 根据文件类型调整MIME类型
  // 创建隐藏的a标签用于下载

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName + '.' + extName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
/**
 * 将文件对象转换为Base64
 * @param file 文件对象
 * @param isNeedPrefix 是否需要返回前缀
 * @returns {Promise<string>} Base64
 */

export async function fileToBase64(file, isNeedPrefix = true) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = reader.result;

      if (typeof result === 'string') {
        if (isNeedPrefix) {
          resolve(result);
        } else {
          const base64Data = result.split(',')[1];
          resolve(base64Data);
        }
      } else {
        reject(new Error('Failed to convert file to base64.'));
      }
    };

    reader.onerror = error => reject(error);

    reader.readAsDataURL(file);
  });
}
/**
 * 将base64格式的数据转换为Blob对象并创建File对象
 * @param base64Data base64格式的数据
 * @param fileName 文件名
 * @returns {File} 文件对象
 */

export function base64ToBlobWithMimeType(base64Data, fileName) {
  // 提取"data:image/*;"之后的内容，找到逗号的位置来分割MIME类型和数据
  const commaIndex = base64Data.indexOf(',');

  if (commaIndex === -1) {
    throw new Error('Invalid base64 data format, no comma found.');
  } // 获取MIME类型


  const mimeType = base64Data.substring('data:image/'.length, commaIndex); // 获取纯净的Base64数据（去掉"data:image/*;"部分）

  const base64Content = base64Data.substring(commaIndex + 1); // 将Base64编码的数据转换为byte数组

  const byteCharacters = atob(base64Content); // 计算byte数组的长度

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  } // 使用byte数组创建Blob对象


  const blob = new Blob(byteArrays, {
    type: mimeType
  }); // 使用Blob对象创建File对象

  return new File([blob], fileName, {
    type: mimeType
  });
}
/**
 * 判断文件是否符合accept的类型
 * @param file 文件对象
 * @param accept 接受的文件类型
 * @returns {boolean}
 */

export const isAcceptFile = (file, accept) => {
  if (accept && file) {
    const accepts = Array.isArray(accept) ? accept : accept.split(',').map(x => x.trim()).filter(x => x);
    const fileExtension = file.name.indexOf('.') > -1 ? file.name.split('.').pop() : '';
    return accepts.some(type => {
      const text = type && type.toLowerCase();
      const fileType = (file.type || '').toLowerCase();

      if (text === fileType) {
        // 类似excel文件这种
        // 比如application/vnd.ms-excel和application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        // 本身就带有.字符的，不能走下面的.jpg等文件扩展名判断处理
        // 所以优先对比input的accept类型和文件对象的type值
        return true;
      }

      if (new RegExp('\/\*').test(text)) {
        // image/* 这种通配的形式处理
        const regExp = new RegExp('\/.*$');
        return fileType.replace(regExp, '') === text.replace(regExp, '');
      }

      if (new RegExp('\..*').test(text)) {
        // .jpg 等后缀名
        return text === `.${fileExtension && fileExtension.toLowerCase()}`;
      }

      return false;
    });
  }

  return !!file;
};
/**
 * 给对象添加前缀
 * @param obj 对象
 * @param prefix 前缀字符
 * @returns {any} 新对象
 */

export function addPrefixToObjectKeys(obj, prefix) {
  return Object.keys(obj).reduce((result, key) => {
    result[prefix + key] = obj[key];
    return result;
  }, {});
}
/**
* 给对象移除前缀
* @param obj 对象
* @param prefix 前缀字符
* @returns {any} 新对象
*/

export function removePrefixFromObjectKeys(obj, prefix) {
  return Object.keys(obj).reduce((result, key) => {
    if (key.startsWith(prefix)) {
      result[key.slice(prefix.length)] = obj[key];
    } else {
      // 如果key中不存在前缀，保持不变
      result[key] = obj[key];
    }

    return result;
  }, {});
}
/**
 * 手机号中间四位显示为*
 */

export const formatPhone = phone => {
  if (!phone) {
    return '';
  }

  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
};
export function maskBankAccountNumber(accountNumber) {
  const newAccountNumber = accountNumber.toString(); // 检查账户号码的长度

  const length = newAccountNumber.length; // 定义要隐藏的中间位数

  const hideLength = 8; // 计算前后显示的位数

  const showLength = Math.floor((length - hideLength) / 2); // 构建掩码后的字符串

  const maskedPart = '*'.repeat(hideLength);
  const maskedAccountNumber = `${newAccountNumber.substring(0, showLength)}${maskedPart}${newAccountNumber.substring(showLength + hideLength)}`;
  return maskedAccountNumber;
}
/**
 * 对象深克隆
 * @param obj 待克隆的对象
 * @returns  克隆后的对象
 */

export const deepClone = obj => {
  return JSON.parse(JSON.stringify(obj));
}; //判断是否是数字或者特殊数字(正负数、小数、科学计数法, 百分比33%, 金额¥12)

export const isValidSpecialNumber = value => {
  return /^[-+]?[0-9]+(\.[0-9]+)?(e[0-9]+)?%?$|¥[0-9]+(\.[0-9]+)?$/.test(value);
}; //格式化时间戳, 返回格式(当天的显示时分, 昨天的显示昨天几时几分, 今年的显示月日时分, 去年的显示年月日时分, 小于10要补0)

export const formatTimestamp = timestamp => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const currentDate = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  if (currentDate.getFullYear() === year) {
    if (currentDate.getMonth() === date.getMonth() && currentDate.getDate() === date.getDate()) {
      return `${hour}:${minute}`;
    } else if (currentDate.getMonth() === date.getMonth() && currentDate.getDate() - date.getDate() === 1) {
      return `昨天 ${hour}:${minute}`;
    } else {
      return `${month}月${day}日 ${hour}:${minute}`;
    }
  } else {
    return `${year}-${month}-${day} ${hour}:${minute}`;
  }
}; //格式化时间戳, 显示几秒前, 几分钟前, 几小时前, 几天前, 几月前, 几年前

export const formatTimestamp2 = timestamp => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const currentDate = new Date();
  const diff = currentDate.getTime() - date.getTime();
  const second = Math.floor(diff / 1000);
  const minute = Math.floor(second / 60);
  const hour = Math.floor(minute / 60);
  const day = Math.floor(hour / 24);
  const month = Math.floor(day / 30);
  const year = Math.floor(month / 12);

  if (year > 0) {
    return `${year}年前`;
  } else if (month > 0) {
    return `${month}月前`;
  } else if (day > 0) {
    return `${day}天前`;
  } else if (hour > 0) {
    return `${hour}小时前`;
  } else if (minute > 0) {
    return `${minute}分钟前`;
  } else {
    return `${second}秒前`;
  }
}; //去除空格并且保留两位小数

export const trimAndFixed = value => {
  const newValue = parseFloat(value.replace(/\s/g, ''));

  if (!isNaN(newValue)) {
    return newValue.toFixed(2).toString();
  } else {
    return '';
  }
}; //只能输入数字

export const onlyNumber = value => {
  return value.replace(/[^\d]/g, '');
}; //只能输入数字和小数点, 且小数点只能输入一次

export const onlyNumberAndDot = value => {
  return value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
};
/**
 * 文件大小转化为KB或MB
 * @param sizeInBytes 文件大小
 * @returns 
 */

export function convertFileSizeToKbOrMb(sizeInBytes) {
  if (sizeInBytes === undefined || sizeInBytes === null) {
    return "Invalid size";
  }

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  } else {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  }
}