const i18n = {
  'en-US': {
    'forget.form.title': 'Reset Password',
    'forget.form.userName.title': 'Phone Number',
    'forget.form.userName.errMsg': 'Phone number cannot be empty',
    'forget.form.userName.placeholder': 'Please enter the phone number',
    'forget.form.newPassword.title': 'New password',
    'forget.form.newPassword.errMsg': 'New password cannot be empty',
    'forget.form.newPassword.placeholder': 'Please enter the new password',
    'forget.form.newPassword.confirm': 'Two passwords entered are inconsistent',
    'forget.form.newPassword.valid': 'Password must be 8-16 characters, including at least 1 uppercase letter, 1 lowercase letter and 1 number, other characters are allowed',
    'forget.form.confirmPassword.title': 'Confirm new password',
    'forget.form.confirmPassword.errMsg': 'Confirm new password cannot be empty',
    'forget.form.confirmPassword.placeholder': 'Please enter the new password again',
    'forget.form.verifyCode.title': 'Verification code',
    'forget.form.verifyCode.errMsg': 'Verification code cannot be empty',
    'forget.form.verifyCode.placeholder': 'Please enter the verification code',
    'forget.form.verifyCode.send': 'Send',
    'forget.form.submit.errMsg': 'Submit error, please try again',
    'forget.form.submit': 'Submit'
  },
  'zh-CN': {
    'forget.form.title': '重置密码',
    'forget.form.cloudAccount.title': '云账号',
    'forget.form.cloudAccount.errMsg': '云账号不能为空',
    'forget.form.cloudAccount.placeholder': '请输入云账号',
    'forget.form.userName.title': '手机号',
    'forget.form.userName.errMsg': '手机号不能为空',
    'forget.form.userName.placeholder': '请输入手机号',
    'forget.form.newPassword.title': '新密码',
    'forget.form.newPassword.errMsg': '新密码不能为空',
    'forget.form.newPassword.placeholder': '请输入新密码',
    'forget.form.newPassword.confirm': '两次密码输入不一致',
    'forget.form.newPassword.valid': '密码必须包含字母和数字，长度在8-20之间',
    'forget.form.confirmPassword.title': '确认新密码',
    'forget.form.confirmPassword.errMsg': '确认新密码不能为空',
    'forget.form.confirmPassword.placeholder': '请再次输入新密码',
    'forget.form.verifyCode.title': '验证码',
    'forget.form.verifyCode.errMsg': '验证码不能为空',
    'forget.form.verifyCode.placeholder': '请输入验证码',
    'forget.form.verifyCode.send': '发送',
    'forget.form.submit.errMsg': '提交出错, 请稍后重试',
    'forget.form.submit': '提交'
  }
};
export default i18n;